import React from "react";
import Radius from "../../icons/radius.svg";
import Check from "../../icons/check.svg";

export default function Fess() {
  return (
    <div style={styles.main}>
      <div style={styles.container}>
        <div style={styles.containerHeader}>Depozito İade İşlemi</div>
        <div style={styles.IconDescContainer}>
          <img style={styles.iconImage} src={Check}></img>
          <img src={Radius}></img>
          <div style={styles.checkText}>Evet</div>
        </div>
        <div style={styles.line}></div>
        <div style={styles.containerHeaderLast}>Bakiye Limiti</div>
        <div style={styles.descContainer}>
          <div style={styles.descText}>2.750 TL</div>
        </div>
        <div style={styles.line}></div>
        <div style={styles.containerHeaderLast}>Harcama Limitleri</div>
        <div style={styles.descContainer}>
          <div style={styles.descText}>2.750 TL</div>
          <div style={styles.descTextGray}>Aylık</div>
        </div>
        <div style={styles.line}></div>

        <div style={styles.containerHeaderLast}>
          <div style={{ flex: 1 }}>Para Çekme </div>
          <div style={styles.rightIcon}>ATM</div>
        </div>
        <div style={styles.descContainer}>
          <div style={styles.descText}>1.000 TL</div>
          <div style={styles.descTextGray}>Yıllık</div>
        </div>
        <div style={styles.line}></div>

        <div style={styles.containerHeaderLast}>Para Transferi</div>
        <div style={styles.descContainer}>
          <div style={styles.descText}>2.750 TL</div>
          <div style={styles.descTextGray}>Aylık - Sadece kendi hesabına</div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  main: {
    backgroundColor: "#F4F4F4",
    display: "flex",
    width: "100%",
    height: "100vh",
    fontFamily: "Poppins",
  },
  container: {
    padding: 18,
    margin: 25,
    backgroundColor: "white",
    borderRadius: "8px",
    width: "100%",
    height: 454,
  },
  containerHeader: {
    fontWeight: "500",
    fontSize: 16,
  },
  containerHeaderLast: {
    marginTop: 14,
    fontWeight: "500",
    display: "flex",
    fontSize: 16,
    justifyItems: "center",
  },
  iconImage: { position: "absolute", top: 7.4, left: 5.8 },
  descContainer: {
    position: "relative",

    justifyItems: "center",
    alignItems: "center",
    marginTop: 3,
    marginBottom: 8,
  },
  IconDescContainer: {
    position: "relative",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    marginTop: 8,
    marginBottom: 8,
  },
  checkText: {
    color: "#2D9D60",
    marginLeft: 5,
    fontWeight: "500",
    fontSize: 16,
  },
  descText: {
    color: "#1F1F1F",

    fontWeight: "400",
    fontSize: 16,
  },
  descTextGray: {
    color: "#666666",

    fontWeight: "400",
    fontSize: 14,
  },
  line: {
    backgroundColor: "#F4F4F4",
    height: 2,
    width: "100%",
    marginTop: 15,
  },
  rightIcon: {
    color: "#666666",
    fontSize: 12,
    fontWeight: "600",
    backgroundColor: "#F5F5F5",
    borderRadius: 13,
    padding: 3,
  },
};
