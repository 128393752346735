import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import i18n from "../../i18n";

export default function FAQ() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    i18n.changeLanguage(
      searchParams.get("lang") ? searchParams.get("lang") : "tr"
    );
  }, []);

  return (
    <div style={styles.main}>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question1.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question1.desc0")}</p>
            <div></div>
            <div>{t("FAQ.question1.desc1")}</div>
            <div>{t("FAQ.question1.desc2")}</div>
            <div>{t("FAQ.question1.desc3")}</div>
            <div>{t("FAQ.question1.desc4")}</div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question2.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <div>{t("FAQ.question2.desc0")}</div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question3.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <div>{t("FAQ.question3.desc0")}</div>
            <div>{t("FAQ.question3.desc1")}</div>
            <div>{t("FAQ.question3.desc2")}</div>
            <div>{t("FAQ.question3.desc3")}</div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question4.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <div>{t("FAQ.question4.desc0")}</div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question5.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question5.desc0")}</p>
            <div>{t("FAQ.question5.desc1")}</div>
            <div>{t("FAQ.question5.desc2")}</div>
            <div>{t("FAQ.question5.desc3")}</div>
            <div>{t("FAQ.question5.desc4")}</div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question6.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question6.desc0")}</p>
            <div>{t("FAQ.question6.desc1")}</div>
            <div>{t("FAQ.question6.desc2")}</div>
            <div>{t("FAQ.question6.desc3")}</div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question17.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question17.desc0")}</p>
            <div>{t("FAQ.question17.desc1")}</div>
            <div>{t("FAQ.question17.desc2")}</div>
            <div>{t("FAQ.question17.desc3")}</div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question18.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question18.desc0")}</p>
            <div>{t("FAQ.question18.desc1")}</div>
            <div>{t("FAQ.question18.desc2")}</div>
            <div>{t("FAQ.question18.desc3")}</div>
            <div>{t("FAQ.question18.desc4")}</div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question7.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question7.desc0")}</p>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question8.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question8.desc0")}</p>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question9.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question9.desc0")}</p>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question10.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question10.desc0")}</p>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question11.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question11.desc0")}</p>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question12.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question12.desc0")}</p>
            <div>{t("FAQ.question12.desc1")}</div>
            <div>{t("FAQ.question12.desc2")}</div>
            <div>{t("FAQ.question12.desc3")}</div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question13.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question13.desc0")}</p>
            <div>{t("FAQ.question13.desc1")}</div>
            <div>{t("FAQ.question13.desc2")}</div>
            <div>{t("FAQ.question13.desc3")}</div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question14.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question14.desc0")}</p>
            <div>{t("FAQ.question14.desc1")}</div>
            <div>{t("FAQ.question14.desc2")}</div>
            <div>{t("FAQ.question14.desc3")}</div>
            <div>{t("FAQ.question14.desc4")}</div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question15.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question15.desc0")}</p>
            <div>{t("FAQ.question15.desc1")}</div>
            <div>{t("FAQ.question15.desc2")}</div>
            <div>{t("FAQ.question15.desc3")}</div>
            <div>{t("FAQ.question15.desc4")}</div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion elevation={0} sx={{ border: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          {t("FAQ.question16.title")}
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <p>{t("FAQ.question16.desc0")}</p>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const styles = {
  main: {
    padding: 22,
  },
  acordionSummary: {
    fontSize: 16,
    fontWeight: "500",
    borderWidth: 0,
    fontFamily: "Poppins",
    padding: 0,
  },
  accordionDetails: {
    textAlign: "left",
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Poppins",
    padding: 0,
  },
};
