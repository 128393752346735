import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Help() {
  return (
    <div style={styles.main}>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          ATM’den nasıl para çekerim?
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <div>
              Bakiyen ve limitlerin dahilinde çekmek istediğin tutarı "Tutar"
              alanına yazdıktan sonra; "Devam Et" butonuna tıklayarak açılan
              kamera ekranından atm ekranındaki karekodu okutup para çekme
              işlemini ATM üzerinden tamamlayabilirsin.
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={styles.acordionSummary}
        >
          İşlem limiti nedir?
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <div style={styles.accordionDetails}>
            <div>
              Kimlik doğrulaması yapmamış kullanıcılar 6493 sayılı kanun gereği,
              belirlenen limitler dahilinde işlem yapabilir. Kimliğini
              doğrulamadıysan “İşlem Limitleri” sayfasından yapabilirsiniz.
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const styles = {
  main: {
    padding: 22,
  },
  acordionSummary: {
    fontSize: 16,
    fontWeight: "500",
    borderWidth: 0,
    fontFamily: "Poppins",
    padding: 0,
  },
  accordionDetails: {
    textAlign: "left",
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Poppins",
    padding: 0,
  },
};
