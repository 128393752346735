import logo from "./logo.svg";
import "./App.css";
import Help from "./screen/Help/Help";
import FAQ from "./screen/FAQ/FAQ";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Fess from "./screen/Fess/Fess";
import "./i18n";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/help" element={<Help />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/fees" element={<Fess />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
